import React from "react";
import { Exchange, ExchangesService } from "../../service";

export interface IExchangeListProps {
  exchanges?: Exchange[];
}

const ExchangeList = (props: IExchangeListProps): React.ReactElement => {
  const [exchanges, setExchanges] = React.useState<Exchange[]>([]);

  const fetchExchanges = React.useCallback(async () => {
    const res = await ExchangesService.exchangesList();
    if (res && res.results) {
      setExchanges(res.results);
    }
  }, []);

  React.useEffect(() => {
    if (props.exchanges) {
      setExchanges(props.exchanges);
    } else {
      fetchExchanges();
    }
  }, [fetchExchanges, props.exchanges]);

  return (
    <>
      <div className="custom-table">
        <ul className="nav nav-pills" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-toggle="pill"
              href="#exchanges"
              role="tab"
              aria-selected="true"
            >
              Exchanges
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="exchanges"
            role="tabpanel"
          >
            <table className="table">
              <thead>
                <tr>
                  <th scope="colgroup">Name</th>
                  <th>ID</th>
                  <th>BotI</th>
                  <th>Running BotI</th>
                  <th>Trades count</th>
                  <th>Total USD_ROI</th>
                  <th>
                    7 day % <br />
                    ROI change
                  </th>
                </tr>
              </thead>
              <tbody>
                {exchanges.map((exchange) => {
                  const totalROI = parseFloat(exchange.stats.total_usd_roi);
                  const last7dROI = parseFloat(exchange.stats.last_7_d_usd_roi);
                  const sign = Math.sign(last7dROI) > 0 ? "+" : "-";
                  const ptcLast7Days =
                    totalROI && last7dROI && last7dROI !== 0
                      ? 100 - ((totalROI - last7dROI) * 100) / totalROI
                      : 0.0;
                  return (
                    <tr key={exchange.id}>
                      <td>{exchange.name}</td>
                      <td className="text-right">{exchange.exchange_id}</td>
                      <td className="text-right">
                        {exchange.stats.bot_instances_count}
                      </td>
                      <td className="text-right">
                        {exchange.stats.bot_instances_running_count}
                      </td>
                      <td className="text-right">
                        {exchange.stats.trades_count}
                      </td>
                      <td className="text-right">
                        {exchange.stats.total_usd_roi}
                      </td>
                      <td
                        className={`text-right ${
                          last7dROI >= 0 ? "green" : "red"
                        }`}
                      >
                        {sign}
                        {ptcLast7Days.toFixed(2)} %
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExchangeList;
