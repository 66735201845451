import React from "react";

import { ProfileService } from "../../service";
import { resetAxios } from "../../service/interceptor";
import { AppContext } from "../../state/AppContext";
import { AppTypes } from "../../state/AppReducer";
import Layout from "../Layout/Layout";
import Login from "./Login";

const Authentiaction = () => {
  const { state, dispatch } = React.useContext(AppContext);

  // fetch profile for access or refresh token
  const initAuthentication = React.useCallback(async () => {
    const profiles = await ProfileService.profileList();
    if (profiles && profiles.length > 0) {
      const profile = profiles[0];
      resetAxios();
      // successfully validated received access token
      // and dispatch successful login to AppContext
      if (profile.authenticated) {
        dispatch({
          type: AppTypes.UpdateProfile,
          payload: {
            authenticated: true,
            username: profile.username,
            firstName: profile.first_name,
            lastName: profile.last_name,
            email: profile.email,
          },
        });
      }
    }
  }, [dispatch]);

  // initialize authentication session (JWT)
  React.useEffect(() => {
    initAuthentication();
  }, [initAuthentication]);

  return <>{state.authenticated ? <Layout /> : <Login />}</>;
};

export default Authentiaction;
