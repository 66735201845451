import React from "react";
import FunctionList from "../../components/Function/FunctionList";
import { Function as IFunction, FunctionsService } from "../../service";

const FunctionPage = (): React.ReactElement => {
  const [functions, setFunctions] = React.useState<IFunction[]>([]);

  const fetchFunctions = React.useCallback(async () => {
    const res = await FunctionsService.functionsList();
    if (res && res.results) {
      setFunctions(res.results);
    }
  }, []);

  React.useEffect(() => {
    fetchFunctions();
  }, [fetchFunctions]);

  return (
    <div className="container-fluid mtb15 no-fluid">
      <FunctionList functions={functions} />
    </div>
  );
};

export default FunctionPage;
