import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/";

var axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    Authorization: localStorage.getItem("access_token")
      ? "Bearer " + localStorage.getItem("access_token")
      : null,
    accept: "application/json",
  },
});

export function resetAxios() {
  axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 20000,
    headers: {
      Authorization: localStorage.getItem("access_token")
        ? "Bearer " + localStorage.getItem("access_token")
        : null,
      accept: "application/json",
    },
  });
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    // Prevent infinite loops
    if (
      error?.response?.status === 401 &&
      originalRequest.url === baseURL + "api/v1/auth/refresh_token/"
    ) {
      window.location.href = "/";
      return Promise.reject(error);
    }

    if (
      error?.response?.data?.code === "token_not_valid" &&
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      (error?.response?.statusText === "Unauthorized" ||
        error?.response?.statusText === "Forbidden")
    ) {
      const refreshToken = localStorage.getItem("refresh_token");

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post("api/v1/auth/refresh_token/", { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem("access_token", response.data.access);
              localStorage.setItem("refresh_token", response.data.refresh);

              axiosInstance.defaults.headers["Authorization"] =
                "Bearer " + response.data.access;
              originalRequest.headers["Authorization"] =
                "Bearer " + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              // console.log(err);
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              window.location.href = "/";
            });
        } else {
          console.log("Refresh token is expired", tokenParts.exp, now);
          window.location.href = "/login";
        }
      } else {
        console.log("Refresh token not available.");
        window.location.href = "/login";
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
