import React from "react";
import {
  Bot,
  BotInstance,
  BotInstancesService,
  BotsService,
} from "../../service";

export interface IBotInstanceListProps {
  botInstances?: BotInstance[];
  bots?: Bot[];
}

const BotList = (props: IBotInstanceListProps): React.ReactElement => {
  const [botInstances, setBotInstances] = React.useState<BotInstance[]>([]);
  const [bots, setBots] = React.useState<Bot[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState<Number>(0);

  const handleClick = (index: Number) => setSelectedIndex(index);

  const fetchBotInstances = React.useCallback(async () => {
    const res = await BotInstancesService.botInstancesList();
    if (res && res.results) {
      setBotInstances(res.results);
    }
  }, []);

  const fetchBots = React.useCallback(async () => {
    const res = await BotsService.botsList();
    if (res && res.results) {
      setBots(res.results);
    }
  }, []);

  React.useEffect(() => {
    if (props.botInstances) {
      setBotInstances(props.botInstances);
    } else {
      fetchBotInstances();
    }

    if (props.bots) {
      setBots(props.bots);
    } else {
      fetchBots();
    }
  }, [fetchBotInstances, fetchBots, props.botInstances, props.bots]);

  return (
    <>
      <div className="custom-table">
        <ul className="nav nav-pills" role="tablist">
          {bots.map((bot, index) => (
            <li className="nav-item">
              <a
                className={`nav-link ${
                  selectedIndex === index ? "active" : ""
                }`}
                data-toggle="pill"
                href={`#bot-instances-${bot.id}`}
                role="tab"
                onClick={() => handleClick(index)}
                aria-selected={selectedIndex === index}
              >
                {bot.name}
              </a>
            </li>
          ))}
        </ul>
        <div className="tab-content">
          {bots.map((bot, index) => (
            <div
              className="tab-pane fade show active"
              id={`bot-instances-${bot.id}`}
              role="tabpanel"
              hidden={selectedIndex !== index}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th>Slug</th>
                    <th>Group</th>
                    <th>State</th>
                    <th>Test</th>
                  </tr>
                </thead>
                <tbody>
                  {botInstances
                    .filter((botInstance) => botInstance.bot === bot.id)
                    .map((botInstance) => (
                      <tr key={botInstance.id}>
                        <td>{botInstance.slug}</td>
                        <td>{botInstance.group}</td>
                        <td>{botInstance.state}</td>
                        <td>
                          {botInstance.test ? (
                            <i className="icon green ion-md-checkmark-circle" />
                          ) : (
                            <i className="icon red ion-ios-globe" />
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BotList;
