import React from "react";

import logoLight from "../../scss/img/logo-light.svg";
import logoDark from "../../scss/img/logo-dark.svg";
import avatar from "../../scss/img/avatar.svg";
import { AppContext } from "../../state/AppContext";
import { AppTypes } from "../../state/AppReducer";
import { Link } from "react-router-dom";

const Header = (): React.ReactElement => {
  const { state, dispatch } = React.useContext(AppContext);

  const doLogout = () => {
    dispatch({
      type: AppTypes.ResetProfile,
    });
  };

  const toggleTheme = () => {
    dispatch({
      type: AppTypes.SetTheme,
      payload: state.theme === "dark" ? "light" : "dark",
    });
  };

  return (
    <header className="dark-bb">
      <nav className="navbar navbar-expanded-lg">
        <Link to="/" className="navbar-brand">
          <img src={state.theme === "dark" ? logoLight : logoDark} alt="logo" />
        </Link>
        {/* <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#headerMenu"
          aria-controls="headerMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="icon ion-md-menu"></i>
        </button> */}

        <div className="navbar-collapse" id="headerMenu">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item dropdown">
              <Link className="nav-link" to="/exchanges" role="button">
                Exchanges
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link" to="/bots" role="button">
                Bots
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link" to="/trades" role="button">
                Trades
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link" to="/functions" role="button">
                Functions
              </Link>
            </li>
            {/* <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Landing Page
              </Link>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="landing-page-dark.html">
                  Landing One
                </Link>
                <Link className="dropdown-item" to="landing-page-dark-two.html">
                  Landing Two
                </Link>
              </div>
            </li> */}
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown header-img-icon">
              <span
                className="nav-link dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img src={avatar} alt="avatar" />
              </span>
              <div className="dropdown-menu">
                <div className="dropdown-header d-flex flex-column align-items-center">
                  <div className="figure mb-3">
                    <img src={avatar} alt="" />
                  </div>
                  <div className="info text-center">
                    <p className="name font-weight-bold mb-0">
                      {state.profile?.firstName} {state.profile?.lastName}
                    </p>
                    {state.profile?.email ? (
                      <p className="email text-muted mb-3">
                        {state.profile?.email}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="dropdown-body">
                  <ul className="profile-nav">
                    {/* <li className="nav-item">
                      <Link to="settings-profile-dark.html" className="nav-link">
                        <i className="icon ion-md-person"></i>
                        <span>Profile</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="settings-wallet-dark.html" className="nav-link">
                        <i className="icon ion-md-wallet"></i>
                        <span>My Wallet</span>
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link to="/settings" className="nav-link">
                        <i className="icon ion-md-settings"></i>
                        <span>Settings</span>
                      </Link>
                    </li>
                    <li className="nav-item" id="changeThemeDark">
                      <Link to="" className="nav-link">
                        <i className="icon ion-md-moon"></i>
                        <span onClick={toggleTheme}>Toggle Theme</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="" className="nav-link red">
                        <i className="icon ion-md-power"></i>
                        <span onClick={doLogout}>Log Out</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
