import React, { createContext, Dispatch, useReducer } from "react";
import {
  AppActions,
  AppProps,
  AppReducer,
  initialAppState,
} from "./AppReducer";

export const AppContext = createContext<{
  state: AppProps;
  dispatch: Dispatch<AppActions>;
}>({
  state: initialAppState,
  dispatch: () => null,
});

interface Props {
  children?: React.ReactElement;
}

export const AppProvider = ({ children }: Props): React.ReactElement => {
  const [state, dispatch] = useReducer(AppReducer, initialAppState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};