import React from "react";

interface TwoColumnProps {
  leftChildren: React.ReactNode;
  rightChildren: React.ReactNode;
}

const TwoColumn = (props: TwoColumnProps): React.ReactElement => {
  return (
    <div className="container-fluid mtb15 no-fluid">
      <div className="row sm-gutters">
        <div className="col-md-8 col-xl-8">{props.leftChildren}</div>
        <div className="col-md-4 col-xl-4">{props.rightChildren}</div>
      </div>
    </div>
  );
};

export default TwoColumn;
