export const tradingviewTicketSymbols = [
  {
    proName: "PHEMEX:BTCUSDT.P",
    title: "BTC PERP",
  },
  {
    proName: "PHEMEX:ETHUSDT.P",
    title: "ETH PERP",
  },
  {
    proName: "PHEMEX:HIGHUSDT.P",
    title: "HIGH PERP",
  },
];
