import React from "react";
import TradeList from "../../components/Trade/TradeList";
import { Trade, TradesService } from "../../service";

const TradePage = (): React.ReactElement => {
  const [trades, setTrades] = React.useState<Trade[]>([]);

  const fetchTrades = React.useCallback(async () => {
    const res = await TradesService.tradesList();
    if (res && res.results) {
      setTrades(res.results);
    }
  }, []);

  React.useEffect(() => {
    fetchTrades();
  }, [fetchTrades]);

  return (
    <div className="container-fluid mtb15 no-fluid">
      <TradeList trades={trades} />
    </div>
  );
};

export default TradePage;
