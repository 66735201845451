import React from "react";
import BotList from "../../components/Bot/Bot";
import {
  Bot,
  BotInstance,
  BotInstancesService,
  BotsService,
} from "../../service";

const BotInstancePage = (): React.ReactElement => {
  const [botInstances, setBotInstances] = React.useState<BotInstance[]>([]);
  const [bots, setBots] = React.useState<Bot[]>([]);

  const fetchBotInstances = React.useCallback(async () => {
    const res = await BotInstancesService.botInstancesList();
    if (res && res.results) {
      setBotInstances(res.results);
    }
  }, []);

  const fetchBots = React.useCallback(async () => {
    const res = await BotsService.botsList();
    if (res && res.results) {
      setBots(res.results);
    }
  }, []);

  React.useEffect(() => {
    fetchBotInstances();
    fetchBots();
  }, [fetchBotInstances, fetchBots]);

  return (
    <div className="container-fluid mtb15 no-fluid">
      <BotList bots={bots} botInstances={botInstances} />
    </div>
  );
};

export default BotInstancePage;
