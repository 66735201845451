import React from "react";

import { Route, Routes } from "react-router-dom";
import { TickerTape } from "react-ts-tradingview-widgets";
import { tradingviewStyles } from "../../styles/tradingview";
import { tradingviewTicketSymbols } from "../../utils/tradingview";

import Header from "./Header";

import BotInstancePage from "../../pages/BotPage/BotPage";
import Dashboard from "../../pages/Dashboard/Dashboard";
import ExchangePage from "../../pages/ExchangePage/ExchangePage";
import FunctionPage from "../../pages/FunctionPage/FunctionPage";
import TradePage from "../../pages/TradePage/TragePage";

const Layout = (): React.ReactElement => {
  return (
    <>
      <div>
        <Header />
        <TickerTape
          colorTheme="dark"
          copyrightStyles={tradingviewStyles}
          symbols={tradingviewTicketSymbols}
        ></TickerTape>
        ;
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/exchanges" element={<ExchangePage />} />
          <Route path="/bots" element={<BotInstancePage />} />
          <Route path="/trades" element={<TradePage />} />
          <Route path="/functions" element={<FunctionPage />} />
        </Routes>
      </div>
    </>
  );
};

export default Layout;
