import React from "react";

import { Function as IFunction, FunctionsService } from "../../service";

export interface IFunctionListProps {
  functions?: IFunction[];
}

const FunctionList = (props: IFunctionListProps): React.ReactElement => {
  const [functions, setFunctions] = React.useState<IFunction[]>([]);

  const fetchFunctions = React.useCallback(async () => {
    const res = await FunctionsService.functionsList();
    if (res && res.results) {
      setFunctions(res.results);
    }
  }, []);

  React.useEffect(() => {
    if (props.functions) {
      setFunctions(props.functions);
    } else {
      fetchFunctions();
    }
  }, [fetchFunctions, props.functions]);

  return (
    <>
      <div className="custom-table">
        <ul className="nav nav-pills" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-toggle="pill"
              href="#functions"
              role="tab"
              aria-selected="true"
            >
              Functions
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="functions"
            role="tabpanel"
          >
            <table className="table">
              <thead>
                <tr>
                  <th>Slug</th>
                  <th>Revision</th>
                  <th>Type</th>
                  <th>Source Name</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {functions.map((func) => (
                  <tr key={func.id}>
                    <td>{func.slug}</td>
                    <td>{func.revision}</td>
                    <td>{func.type}</td>
                    <td>{func.source_name}</td>
                    <td>{func.note || "---"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default FunctionList;
