import React from "react";

import Authentiaction from "./containers/Authentication/Authentication";
import { serviceOptions } from "./service";
import axiosInstance from "./service/interceptor";
import { AppContext } from "./state/AppContext";

// initialize the axios instance on the service options
// for any further API calls.
serviceOptions.axios = axiosInstance;

function App() {
  const { state } = React.useContext(AppContext);
  return (
    <div id={state.theme}>
      <Authentiaction />
    </div>
  );
}

export default App;
