import React from "react";
import moment from "moment";
import { Trade, TradesService } from "../../service";

export interface ITradeListProps {
  trades?: Trade[];
}

const TradeList = (props: ITradeListProps): React.ReactElement => {
  const [trades, setTrades] = React.useState<Trade[]>([]);

  const fetchTrades = React.useCallback(async () => {
    const res = await TradesService.tradesList();
    if (res && res.results) {
      setTrades(res.results);
    }
  }, []);

  React.useEffect(() => {
    if (props.trades) {
      setTrades(props.trades);
    } else {
      fetchTrades();
    }
  }, [fetchTrades, props.trades]);

  return (
    <>
      <div className="custom-table">
        <ul className="nav nav-pills" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-toggle="pill"
              href="#Trades"
              role="tab"
              aria-selected="true"
            >
              Trades
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="Trades"
            role="tabpanel"
          >
            <table className="table">
              <thead>
                <tr>
                  <th>Slug</th>
                  <th>Side</th>
                  <th>Symbol</th>
                  <th>Leverage</th>
                  <th>Trade Date Open</th>
                  <th>USD ROI</th>
                  <th>Pct ROI</th>
                </tr>
              </thead>
              <tbody>
                {trades.map((trade) => {
                  const usdROI = trade.usd_roi
                    ? parseFloat(trade.usd_roi).toFixed(2)
                    : 0;
                  const pctROI = trade.pct_roi
                    ? parseFloat(trade.pct_roi).toFixed(2)
                    : 0;
                  return (
                    <tr key={trade.id}>
                      <td>{trade.slug}</td>
                      <td>{trade.side}</td>
                      <td>{trade.symbol.toUpperCase()}</td>
                      <td className="text-right">
                        {parseFloat(trade.leverage || "0").toFixed(1)}
                      </td>
                      <td>
                        {moment(trade.date_open).format("DD MMM YYYY HH:mm")}
                      </td>
                      <td className="text-right">{usdROI}</td>
                      <td
                        className={`text-right ${
                          pctROI >= 0 ? "green" : "red"
                        }`}
                      >
                        {pctROI > 0 ? "+" : ""}
                        {pctROI}%
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeList;
