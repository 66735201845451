import React from "react";

import { AccessTokenService } from "../../service";

const Login = (): React.ReactElement => {
  const [loading, setLoading] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const doLogin = React.useCallback(async () => {
    //set loading state to true, until request got a response
    setLoading(true);
    // request token from api with username and password
    const res = await AccessTokenService.authObtainTokenCreate({
      body: { username: username, password: password },
    });
    // process with a response from backend
    if (res && res.access && res.refresh) {
      // access and refresh token received and storing in localStorage
      localStorage.setItem("access_token", res.access);
      localStorage.setItem("refresh_token", res.refresh);
      // refresh the current request session after successful login
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    } else {
      // token request failed
      console.error("token request failed");
      setLoading(false);
    }
  }, [username, password, setLoading]);

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    doLogin();
  };

  return (
    <div className="vh-100 d-flex justify-content-center">
      <div className="form-access my-auto">
        <form onSubmit={handleSubmit}>
          <span>krabs.io</span>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Username"
              onChange={(e) => setUsername(e.currentTarget.value)}
              value={username}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              onChange={(e) => setPassword(e.currentTarget.value)}
              value={password}
            />
          </div>
          <span>
            <br />! BETA VERSION !
          </span>
          {loading === false ? (
            <button type="submit" className="btn btn-primary" onClick={doLogin}>
              Login
            </button>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default Login;
