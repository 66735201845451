import React from "react";

import {
  MarketOverview,
  MarketOverviewTab,
  MiniChart,
} from "react-ts-tradingview-widgets";

import BotInstanceList from "../../components/BotInstance/BotInstanceList";
import ExchangeList from "../../components/Exchange/ExchangeList";
import TradeList from "../../components/Trade/TradeList";
import TwoColumn from "../../containers/Layout/TwoColumn";
import { tradingviewStyles } from "../../styles/tradingview";

const tabs: MarketOverviewTab[] = [
  {
    title: "Crypto",
    originalTitle: "",
    symbols: [
      // {
      //   s: "PHEMEX:BTCUSDT.P",
      //   d: "PHEMEX",
      // },
      {
        s: "PHEMEX:ETHUSDT.P",
        d: "PHEMEX",
      },
      {
        s: "PHEMEX:HIGHUSDT.P",
        d: "PHEMEX",
      },
      {
        s: "PHEMEX:RENUSDT.P",
        d: "PHEMEX",
      },
    ],
  },
];

const Dashboard = (): React.ReactElement => {
  const leftChildren = (
    <>
      <div className="mb15">
        <BotInstanceList />
      </div>
      <div className="mb15">
        <TradeList />
      </div>
    </>
  );

  const rightChildren = (
    <>
      <div className="mb15">
        <ExchangeList />
      </div>

      <div className="mb15">
        <MiniChart
          colorTheme="dark"
          width="100%"
          height={250}
          symbol="PHEMEX:BTCUSDT.P"
          copyrightStyles={tradingviewStyles}
        ></MiniChart>
      </div>
      <div className="mb15">
        <MarketOverview
          colorTheme="dark"
          height={500}
          width="100%"
          copyrightStyles={tradingviewStyles}
          tabs={tabs}
          showFloatingTooltip
        ></MarketOverview>
      </div>
    </>
  );
  return (
    <TwoColumn leftChildren={leftChildren} rightChildren={rightChildren} />
  );
};

export default Dashboard;
