import React from "react";

import ExchangeList from "../../components/Exchange/ExchangeList";
import TwoColumn from "../../containers/Layout/TwoColumn";
import { Exchange, ExchangesService } from "../../service";

const ExchangePage = (): React.ReactElement => {
  const [exchanges, setExchanges] = React.useState<Exchange[]>([]);

  const fetchExchanges = React.useCallback(async () => {
    const res = await ExchangesService.exchangesList();
    if (res && res.results) {
      setExchanges(res.results);
    }
  }, []);

  React.useEffect(() => {
    fetchExchanges();
  }, [fetchExchanges]);

  const leftChildren = <ExchangeList exchanges={exchanges} />;
  const rightChildren = <></>;
  return (
    <TwoColumn leftChildren={leftChildren} rightChildren={rightChildren} />
  );
};

export default ExchangePage;
