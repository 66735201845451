/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccessTokenService {
  /**
   * Obtain Access Token
   */
  static authObtainTokenCreate(
    params: {
      /** requestBody */
      body?: TokenObtain;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TokenObtainResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/auth/obtain_token/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Use Refresh Token
   */
  static authRefreshTokenCreate(
    params: {
      /** requestBody */
      body?: TokenRefresh;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TokenRefreshResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/auth/refresh_token/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class BotInstanceLogsService {
  /**
   * List
   */
  static botInstanceLogsList(
    params: {
      /**  */
      botInstance?: number;
      /**  */
      group?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedBotInstanceLogList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bot-instance-logs/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        bot_instance: params['botInstance'],
        group: params['group'],
        page: params['page'],
        page_size: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve
   */
  static botInstanceLogsRetrieve(
    params: {
      /** A unique integer value identifying this Bot Instance Log. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BotInstanceLog> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bot-instance-logs/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class BotInstancesService {
  /**
   * List
   */
  static botInstancesList(
    params: {
      /**  */
      bot?: number;
      /**  */
      exchange?: number;
      /**  */
      group?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /**  */
      slug?: string;
      /** * `stopped` - Stopped
* `paused` - Paused
* `running` - Running

* `stopped` - Stopped
* `paused` - Paused
* `running` - Running */
      state?: string;
      /**  */
      test?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedBotInstanceList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bot-instances/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        bot: params['bot'],
        exchange: params['exchange'],
        group: params['group'],
        page: params['page'],
        page_size: params['pageSize'],
        slug: params['slug'],
        state: params['state'],
        test: params['test']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Create
   */
  static botInstancesCreate(
    params: {
      /** requestBody */
      body?: BotInstance;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BotInstance> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bot-instances/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve
   */
  static botInstancesRetrieve(
    params: {
      /** A unique integer value identifying this Bot Instance. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BotInstance> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bot-instances/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update
   */
  static botInstancesUpdate(
    params: {
      /** A unique integer value identifying this Bot Instance. */
      id: number;
      /** requestBody */
      body?: BotInstance;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BotInstance> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bot-instances/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Partial Update
   */
  static botInstancesPartialUpdate(
    params: {
      /** A unique integer value identifying this Bot Instance. */
      id: number;
      /** requestBody */
      body?: PatchedBotInstance;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BotInstance> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bot-instances/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete
   */
  static botInstancesDestroy(
    params: {
      /** A unique integer value identifying this Bot Instance. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bot-instances/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class BotsService {
  /**
   * List
   */
  static botsList(
    params: {
      /**  */
      active?: boolean;
      /**  */
      name?: string;
      /**  */
      owner?: number;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedBotList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bots/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        active: params['active'],
        name: params['name'],
        owner: params['owner'],
        page: params['page'],
        page_size: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Create
   */
  static botsCreate(
    params: {
      /** requestBody */
      body?: Bot;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Bot> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bots/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve
   */
  static botsRetrieve(
    params: {
      /** A unique integer value identifying this Bot. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Bot> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bots/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update
   */
  static botsUpdate(
    params: {
      /** A unique integer value identifying this Bot. */
      id: number;
      /** requestBody */
      body?: Bot;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Bot> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bots/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Partial Update
   */
  static botsPartialUpdate(
    params: {
      /** A unique integer value identifying this Bot. */
      id: number;
      /** requestBody */
      body?: PatchedBot;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Bot> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bots/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete
   */
  static botsDestroy(
    params: {
      /** A unique integer value identifying this Bot. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/bots/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ExchangesService {
  /**
   * List
   */
  static exchangesList(
    params: {
      /**  */
      owner?: number;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedExchangeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/exchanges/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { owner: params['owner'], page: params['page'], page_size: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Create
   */
  static exchangesCreate(
    params: {
      /** requestBody */
      body?: Exchange;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Exchange> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/exchanges/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve
   */
  static exchangesRetrieve(
    params: {
      /** A unique integer value identifying this Exchange. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Exchange> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/exchanges/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update
   */
  static exchangesUpdate(
    params: {
      /** A unique integer value identifying this Exchange. */
      id: number;
      /** requestBody */
      body?: Exchange;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Exchange> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/exchanges/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Partial Update
   */
  static exchangesPartialUpdate(
    params: {
      /** A unique integer value identifying this Exchange. */
      id: number;
      /** requestBody */
      body?: PatchedExchange;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Exchange> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/exchanges/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete
   */
  static exchangesDestroy(
    params: {
      /** A unique integer value identifying this Exchange. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/exchanges/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class FunctionsService {
  /**
   * List
   */
  static functionsList(
    params: {
      /**  */
      owner?: number;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /**  */
      slug?: string;
      /** * `opener_class` - Opener Class
* `closer_class` - Closer Class
* `invest_function` - Invest Function
* `trade_strategy_function` - Trade Strategy Function

* `opener_class` - Opener Class
* `closer_class` - Closer Class
* `invest_function` - Invest Function
* `trade_strategy_function` - Trade Strategy Function */
      type?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedFunctionList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/functions/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        owner: params['owner'],
        page: params['page'],
        page_size: params['pageSize'],
        slug: params['slug'],
        type: params['type']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Create
   */
  static functionsCreate(
    params: {
      /** requestBody */
      body?: Function;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Function> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/functions/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve
   */
  static functionsRetrieve(
    params: {
      /** A unique integer value identifying this Function. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Function> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/functions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update
   */
  static functionsUpdate(
    params: {
      /** A unique integer value identifying this Function. */
      id: number;
      /** requestBody */
      body?: Function;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Function> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/functions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Partial Update
   */
  static functionsPartialUpdate(
    params: {
      /** A unique integer value identifying this Function. */
      id: number;
      /** requestBody */
      body?: PatchedFunction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Function> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/functions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete
   */
  static functionsDestroy(
    params: {
      /** A unique integer value identifying this Function. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/functions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class OpenapiJsonService {
  /**
   *
   */
  static openapiJsonRetrieve(
    params: {
      /**  */
      lang?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/openapi.json';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { lang: params['lang'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class OrdersService {
  /**
   * List
   */
  static ordersList(
    params: {
      /**  */
      currency?: string;
      /**  */
      exchange?: number;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /**  */
      side?: string;
      /** * `open` - Open
* `closed` - Closed
* `cancelled` - Cancelled
* `failed` - Failed

* `open` - Open
* `closed` - Closed
* `cancelled` - Cancelled
* `failed` - Failed */
      state?: string;
      /**  */
      symbol?: string;
      /**  */
      type?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedOrderList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/orders/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        currency: params['currency'],
        exchange: params['exchange'],
        page: params['page'],
        page_size: params['pageSize'],
        side: params['side'],
        state: params['state'],
        symbol: params['symbol'],
        type: params['type']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve
   */
  static ordersRetrieve(
    params: {
      /** A unique integer value identifying this Order. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Order> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/orders/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ProfileService {
  /**
   * Fetch Profile
   */
  static profileList(options: IRequestOptions = {}): Promise<Profile[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/profile/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class TradesService {
  /**
   * List
   */
  static tradesList(
    params: {
      /**  */
      bot?: number;
      /**  */
      botInstance?: number;
      /**  */
      closeReason?: string;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      pageSize?: number;
      /**  */
      parent?: number;
      /** * `long` - Long
* `short` - Short

* `long` - Long
* `short` - Short */
      side?: string;
      /**  */
      slug?: string;
      /**  */
      symbol?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedTradeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/trades/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        bot: params['bot'],
        bot_instance: params['botInstance'],
        close_reason: params['closeReason'],
        page: params['page'],
        page_size: params['pageSize'],
        parent: params['parent'],
        side: params['side'],
        slug: params['slug'],
        symbol: params['symbol']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Retrieve
   */
  static tradesRetrieve(
    params: {
      /** A unique integer value identifying this Trade. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Trade> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/trades/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class Bot {
  /**  */
  'id': number;

  /**  */
  'created': Date;

  /**  */
  'updated': Date;

  /**  */
  'active'?: boolean;

  /**  */
  'name': string;

  /**  */
  'telegram_api_key'?: string;

  /**  */
  'telegram_chat_id'?: string;

  /**  */
  'owner'?: number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['created'] = data['created'];
    this['updated'] = data['updated'];
    this['active'] = data['active'];
    this['name'] = data['name'];
    this['telegram_api_key'] = data['telegram_api_key'];
    this['telegram_chat_id'] = data['telegram_chat_id'];
    this['owner'] = data['owner'];
  }

  public static validationModel = {
    id: { required: true },
    created: { required: true },
    updated: { required: true },
    name: { required: true, maxLength: 45 },
    telegram_api_key: { maxLength: 145 },
    telegram_chat_id: { maxLength: 145 }
  };
}

export class BotInstance {
  /**  */
  'id': number;

  /**  */
  'created': Date;

  /**  */
  'updated': Date;

  /** Choose an Exchange to consume data from.

* `None` - Binance
* `phemex` - PHEMEX */
  'exchange_data'?: AllExchangeDataTypes;

  /**  */
  'slug': string;

  /**  */
  'group'?: string;

  /**  */
  'state'?: AllStateTypes;

  /**  */
  'bank'?: number;

  /**  */
  'test'?: boolean;

  /**  */
  'bot_params'?: object;

  /**  */
  'input_data'?: object;

  /**  */
  'ta_data'?: object;

  /**  */
  'yield_intervals': object;

  /**  */
  'opener_fn_rev'?: number;

  /**  */
  'opener_class'?: AllOpenerClassTypes;

  /**  */
  'opener_params'?: object;

  /**  */
  'closer_fn_rev'?: number;

  /**  */
  'closer_class'?: AllCloserClassTypes;

  /**  */
  'closer_params'?: object;

  /**  */
  'invest_fn_rev'?: number;

  /**  */
  'invest_function'?: string;

  /**  */
  'invest_params'?: object;

  /**  */
  'trade_strategies'?: object;

  /**  */
  'trade_strategies_params'?: object;

  /**  */
  'number_of_trades'?: number;

  /**  */
  'usd_roi'?: number;

  /**  */
  'pct_roi'?: number;

  /**  */
  'bot'?: number;

  /** Choose an Exchange to place orders on the backend. */
  'exchange'?: number;

  /**  */
  'opener_fn'?: number;

  /**  */
  'closer_fn'?: number;

  /**  */
  'invest_fn'?: number;

  /**  */
  'trade_strategies_fn': number[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['created'] = data['created'];
    this['updated'] = data['updated'];
    this['exchange_data'] = data['exchange_data'];
    this['slug'] = data['slug'];
    this['group'] = data['group'];
    this['state'] = data['state'];
    this['bank'] = data['bank'];
    this['test'] = data['test'];
    this['bot_params'] = data['bot_params'];
    this['input_data'] = data['input_data'];
    this['ta_data'] = data['ta_data'];
    this['yield_intervals'] = data['yield_intervals'];
    this['opener_fn_rev'] = data['opener_fn_rev'];
    this['opener_class'] = data['opener_class'];
    this['opener_params'] = data['opener_params'];
    this['closer_fn_rev'] = data['closer_fn_rev'];
    this['closer_class'] = data['closer_class'];
    this['closer_params'] = data['closer_params'];
    this['invest_fn_rev'] = data['invest_fn_rev'];
    this['invest_function'] = data['invest_function'];
    this['invest_params'] = data['invest_params'];
    this['trade_strategies'] = data['trade_strategies'];
    this['trade_strategies_params'] = data['trade_strategies_params'];
    this['number_of_trades'] = data['number_of_trades'];
    this['usd_roi'] = data['usd_roi'];
    this['pct_roi'] = data['pct_roi'];
    this['bot'] = data['bot'];
    this['exchange'] = data['exchange'];
    this['opener_fn'] = data['opener_fn'];
    this['closer_fn'] = data['closer_fn'];
    this['invest_fn'] = data['invest_fn'];
    this['trade_strategies_fn'] = data['trade_strategies_fn'];
  }

  public static validationModel = {
    id: { required: true },
    created: { required: true },
    updated: { required: true },
    slug: { required: true, maxLength: 255 },
    group: { maxLength: 45 },
    yield_intervals: { required: true },
    invest_function: { maxLength: 45 },
    trade_strategies_fn: { required: true }
  };
}

export class BotInstanceLog {
  /**  */
  'id': number;

  /**  */
  'created': Date;

  /**  */
  'updated': Date;

  /**  */
  'group': string;

  /**  */
  'message'?: string;

  /**  */
  'bot_instance': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['created'] = data['created'];
    this['updated'] = data['updated'];
    this['group'] = data['group'];
    this['message'] = data['message'];
    this['bot_instance'] = data['bot_instance'];
  }

  public static validationModel = {
    id: { required: true },
    created: { required: true },
    updated: { required: true },
    group: { required: true, maxLength: 45 },
    bot_instance: { required: true }
  };
}

export class ErrorResponse {
  /**  */
  'detail': string;

  constructor(data: undefined | any = {}) {
    this['detail'] = data['detail'];
  }

  public static validationModel = {
    detail: { required: true }
  };
}

export class Exchange {
  /**  */
  'id': number;

  /**  */
  'stats': CombinedStatsTypes;

  /**  */
  'created': Date;

  /**  */
  'updated': Date;

  /**  */
  'name': string;

  /**  */
  'exchange_id'?: string;

  /**  */
  'api_key'?: string;

  /**  */
  'api_secret'?: string;

  /**  */
  'secrets_payload'?: object;

  /**  */
  'owner': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['stats'] = data['stats'];
    this['created'] = data['created'];
    this['updated'] = data['updated'];
    this['name'] = data['name'];
    this['exchange_id'] = data['exchange_id'];
    this['api_key'] = data['api_key'];
    this['api_secret'] = data['api_secret'];
    this['secrets_payload'] = data['secrets_payload'];
    this['owner'] = data['owner'];
  }

  public static validationModel = {
    id: { required: true },
    stats: { required: true },
    created: { required: true },
    updated: { required: true },
    name: { required: true, maxLength: 45 },
    exchange_id: { maxLength: 45 },
    api_key: { maxLength: 145 },
    api_secret: { maxLength: 145 },
    owner: { required: true }
  };
}

export class ExchangeStats {
  /**  */
  'bot_instances_count': number;

  /**  */
  'bot_instances_running_count': number;

  /**  */
  'trades_count': number;

  /**  */
  'total_usd_roi': string;

  /**  */
  'last_7_d_usd_roi': string;

  constructor(data: undefined | any = {}) {
    this['bot_instances_count'] = data['bot_instances_count'];
    this['bot_instances_running_count'] = data['bot_instances_running_count'];
    this['trades_count'] = data['trades_count'];
    this['total_usd_roi'] = data['total_usd_roi'];
    this['last_7_d_usd_roi'] = data['last_7_d_usd_roi'];
  }

  public static validationModel = {
    bot_instances_count: { required: true },
    bot_instances_running_count: { required: true },
    trades_count: { required: true },
    total_usd_roi: { required: true },
    last_7_d_usd_roi: { required: true }
  };
}

export class Function {
  /**  */
  'id': number;

  /**  */
  'revision': number;

  /**  */
  'created': Date;

  /**  */
  'updated': Date;

  /**  */
  'slug'?: string;

  /**  */
  'type'?: AllTypeTypes;

  /**  */
  'source_name'?: string;

  /**  */
  'source_code'?: string;

  /**  */
  'note'?: string;

  /**  */
  'owner'?: number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['revision'] = data['revision'];
    this['created'] = data['created'];
    this['updated'] = data['updated'];
    this['slug'] = data['slug'];
    this['type'] = data['type'];
    this['source_name'] = data['source_name'];
    this['source_code'] = data['source_code'];
    this['note'] = data['note'];
    this['owner'] = data['owner'];
  }

  public static validationModel = {
    id: { required: true },
    revision: { required: true },
    created: { required: true },
    updated: { required: true },
    slug: { maxLength: 45 },
    source_name: { maxLength: 45 }
  };
}

export class Order {
  /**  */
  'id': number;

  /**  */
  'created': Date;

  /**  */
  'updated': Date;

  /**  */
  'state'?: OrderStateEnum;

  /**  */
  'code'?: string;

  /**  */
  'type'?: string;

  /**  */
  'cross_margin'?: boolean;

  /**  */
  'symbol'?: string;

  /**  */
  'side'?: string;

  /**  */
  'currency'?: string;

  /**  */
  'leverage'?: string;

  /**  */
  'amount'?: string;

  /**  */
  'params'?: object;

  /**  */
  'close_reason'?: string;

  /**  */
  'exchange_price_open'?: string;

  /**  */
  'exchange_price_close'?: string;

  /**  */
  'exchange_order_id_open'?: string;

  /**  */
  'exchange_client_id_open'?: string;

  /**  */
  'exchange_order_id_close'?: string;

  /**  */
  'exchange_client_id_close'?: string;

  /**  */
  'exchange_order_open'?: Date;

  /**  */
  'exchange_order_close'?: Date;

  /**  */
  'exchange': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['created'] = data['created'];
    this['updated'] = data['updated'];
    this['state'] = data['state'];
    this['code'] = data['code'];
    this['type'] = data['type'];
    this['cross_margin'] = data['cross_margin'];
    this['symbol'] = data['symbol'];
    this['side'] = data['side'];
    this['currency'] = data['currency'];
    this['leverage'] = data['leverage'];
    this['amount'] = data['amount'];
    this['params'] = data['params'];
    this['close_reason'] = data['close_reason'];
    this['exchange_price_open'] = data['exchange_price_open'];
    this['exchange_price_close'] = data['exchange_price_close'];
    this['exchange_order_id_open'] = data['exchange_order_id_open'];
    this['exchange_client_id_open'] = data['exchange_client_id_open'];
    this['exchange_order_id_close'] = data['exchange_order_id_close'];
    this['exchange_client_id_close'] = data['exchange_client_id_close'];
    this['exchange_order_open'] = data['exchange_order_open'];
    this['exchange_order_close'] = data['exchange_order_close'];
    this['exchange'] = data['exchange'];
  }

  public static validationModel = {
    id: { required: true },
    created: { required: true },
    updated: { required: true },
    code: { maxLength: 45 },
    type: { maxLength: 45 },
    symbol: { maxLength: 45 },
    side: { maxLength: 45 },
    currency: { maxLength: 45 },
    leverage: { maxLength: 45 },
    exchange_order_id_open: { maxLength: 45 },
    exchange_client_id_open: { maxLength: 45 },
    exchange_order_id_close: { maxLength: 45 },
    exchange_client_id_close: { maxLength: 45 },
    exchange: { required: true }
  };
}

export class PaginatedBotInstanceList {
  /**  */
  'count'?: number;

  /**  */
  'next'?: string;

  /**  */
  'previous'?: string;

  /**  */
  'results'?: BotInstance[];

  constructor(data: undefined | any = {}) {
    this['count'] = data['count'];
    this['next'] = data['next'];
    this['previous'] = data['previous'];
    this['results'] = data['results'];
  }

  public static validationModel = {};
}

export class PaginatedBotInstanceLogList {
  /**  */
  'count'?: number;

  /**  */
  'next'?: string;

  /**  */
  'previous'?: string;

  /**  */
  'results'?: BotInstanceLog[];

  constructor(data: undefined | any = {}) {
    this['count'] = data['count'];
    this['next'] = data['next'];
    this['previous'] = data['previous'];
    this['results'] = data['results'];
  }

  public static validationModel = {};
}

export class PaginatedBotList {
  /**  */
  'count'?: number;

  /**  */
  'next'?: string;

  /**  */
  'previous'?: string;

  /**  */
  'results'?: Bot[];

  constructor(data: undefined | any = {}) {
    this['count'] = data['count'];
    this['next'] = data['next'];
    this['previous'] = data['previous'];
    this['results'] = data['results'];
  }

  public static validationModel = {};
}

export class PaginatedExchangeList {
  /**  */
  'count'?: number;

  /**  */
  'next'?: string;

  /**  */
  'previous'?: string;

  /**  */
  'results'?: Exchange[];

  constructor(data: undefined | any = {}) {
    this['count'] = data['count'];
    this['next'] = data['next'];
    this['previous'] = data['previous'];
    this['results'] = data['results'];
  }

  public static validationModel = {};
}

export class PaginatedFunctionList {
  /**  */
  'count'?: number;

  /**  */
  'next'?: string;

  /**  */
  'previous'?: string;

  /**  */
  'results'?: Function[];

  constructor(data: undefined | any = {}) {
    this['count'] = data['count'];
    this['next'] = data['next'];
    this['previous'] = data['previous'];
    this['results'] = data['results'];
  }

  public static validationModel = {};
}

export class PaginatedOrderList {
  /**  */
  'count'?: number;

  /**  */
  'next'?: string;

  /**  */
  'previous'?: string;

  /**  */
  'results'?: Order[];

  constructor(data: undefined | any = {}) {
    this['count'] = data['count'];
    this['next'] = data['next'];
    this['previous'] = data['previous'];
    this['results'] = data['results'];
  }

  public static validationModel = {};
}

export class PaginatedTradeList {
  /**  */
  'count'?: number;

  /**  */
  'next'?: string;

  /**  */
  'previous'?: string;

  /**  */
  'results'?: Trade[];

  constructor(data: undefined | any = {}) {
    this['count'] = data['count'];
    this['next'] = data['next'];
    this['previous'] = data['previous'];
    this['results'] = data['results'];
  }

  public static validationModel = {};
}

export class PatchedBot {
  /**  */
  'id'?: number;

  /**  */
  'created'?: Date;

  /**  */
  'updated'?: Date;

  /**  */
  'active'?: boolean;

  /**  */
  'name'?: string;

  /**  */
  'telegram_api_key'?: string;

  /**  */
  'telegram_chat_id'?: string;

  /**  */
  'owner'?: number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['created'] = data['created'];
    this['updated'] = data['updated'];
    this['active'] = data['active'];
    this['name'] = data['name'];
    this['telegram_api_key'] = data['telegram_api_key'];
    this['telegram_chat_id'] = data['telegram_chat_id'];
    this['owner'] = data['owner'];
  }

  public static validationModel = {
    name: { maxLength: 45 },
    telegram_api_key: { maxLength: 145 },
    telegram_chat_id: { maxLength: 145 }
  };
}

export class PatchedBotInstance {
  /**  */
  'id'?: number;

  /**  */
  'created'?: Date;

  /**  */
  'updated'?: Date;

  /** Choose an Exchange to consume data from.

* `None` - Binance
* `phemex` - PHEMEX */
  'exchange_data'?: AllExchangeDataTypes;

  /**  */
  'slug'?: string;

  /**  */
  'group'?: string;

  /**  */
  'state'?: AllStateTypes;

  /**  */
  'bank'?: number;

  /**  */
  'test'?: boolean;

  /**  */
  'bot_params'?: object;

  /**  */
  'input_data'?: object;

  /**  */
  'ta_data'?: object;

  /**  */
  'yield_intervals'?: object;

  /**  */
  'opener_fn_rev'?: number;

  /**  */
  'opener_class'?: AllOpenerClassTypes;

  /**  */
  'opener_params'?: object;

  /**  */
  'closer_fn_rev'?: number;

  /**  */
  'closer_class'?: AllCloserClassTypes;

  /**  */
  'closer_params'?: object;

  /**  */
  'invest_fn_rev'?: number;

  /**  */
  'invest_function'?: string;

  /**  */
  'invest_params'?: object;

  /**  */
  'trade_strategies'?: object;

  /**  */
  'trade_strategies_params'?: object;

  /**  */
  'number_of_trades'?: number;

  /**  */
  'usd_roi'?: number;

  /**  */
  'pct_roi'?: number;

  /**  */
  'bot'?: number;

  /** Choose an Exchange to place orders on the backend. */
  'exchange'?: number;

  /**  */
  'opener_fn'?: number;

  /**  */
  'closer_fn'?: number;

  /**  */
  'invest_fn'?: number;

  /**  */
  'trade_strategies_fn'?: number[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['created'] = data['created'];
    this['updated'] = data['updated'];
    this['exchange_data'] = data['exchange_data'];
    this['slug'] = data['slug'];
    this['group'] = data['group'];
    this['state'] = data['state'];
    this['bank'] = data['bank'];
    this['test'] = data['test'];
    this['bot_params'] = data['bot_params'];
    this['input_data'] = data['input_data'];
    this['ta_data'] = data['ta_data'];
    this['yield_intervals'] = data['yield_intervals'];
    this['opener_fn_rev'] = data['opener_fn_rev'];
    this['opener_class'] = data['opener_class'];
    this['opener_params'] = data['opener_params'];
    this['closer_fn_rev'] = data['closer_fn_rev'];
    this['closer_class'] = data['closer_class'];
    this['closer_params'] = data['closer_params'];
    this['invest_fn_rev'] = data['invest_fn_rev'];
    this['invest_function'] = data['invest_function'];
    this['invest_params'] = data['invest_params'];
    this['trade_strategies'] = data['trade_strategies'];
    this['trade_strategies_params'] = data['trade_strategies_params'];
    this['number_of_trades'] = data['number_of_trades'];
    this['usd_roi'] = data['usd_roi'];
    this['pct_roi'] = data['pct_roi'];
    this['bot'] = data['bot'];
    this['exchange'] = data['exchange'];
    this['opener_fn'] = data['opener_fn'];
    this['closer_fn'] = data['closer_fn'];
    this['invest_fn'] = data['invest_fn'];
    this['trade_strategies_fn'] = data['trade_strategies_fn'];
  }

  public static validationModel = {
    slug: { maxLength: 255 },
    group: { maxLength: 45 },
    invest_function: { maxLength: 45 }
  };
}

export class PatchedExchange {
  /**  */
  'id'?: number;

  /**  */
  'stats'?: CombinedStatsTypes;

  /**  */
  'created'?: Date;

  /**  */
  'updated'?: Date;

  /**  */
  'name'?: string;

  /**  */
  'exchange_id'?: string;

  /**  */
  'api_key'?: string;

  /**  */
  'api_secret'?: string;

  /**  */
  'secrets_payload'?: object;

  /**  */
  'owner'?: number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['stats'] = data['stats'];
    this['created'] = data['created'];
    this['updated'] = data['updated'];
    this['name'] = data['name'];
    this['exchange_id'] = data['exchange_id'];
    this['api_key'] = data['api_key'];
    this['api_secret'] = data['api_secret'];
    this['secrets_payload'] = data['secrets_payload'];
    this['owner'] = data['owner'];
  }

  public static validationModel = {
    name: { maxLength: 45 },
    exchange_id: { maxLength: 45 },
    api_key: { maxLength: 145 },
    api_secret: { maxLength: 145 }
  };
}

export class PatchedFunction {
  /**  */
  'id'?: number;

  /**  */
  'revision'?: number;

  /**  */
  'created'?: Date;

  /**  */
  'updated'?: Date;

  /**  */
  'slug'?: string;

  /**  */
  'type'?: AllTypeTypes;

  /**  */
  'source_name'?: string;

  /**  */
  'source_code'?: string;

  /**  */
  'note'?: string;

  /**  */
  'owner'?: number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['revision'] = data['revision'];
    this['created'] = data['created'];
    this['updated'] = data['updated'];
    this['slug'] = data['slug'];
    this['type'] = data['type'];
    this['source_name'] = data['source_name'];
    this['source_code'] = data['source_code'];
    this['note'] = data['note'];
    this['owner'] = data['owner'];
  }

  public static validationModel = {
    slug: { maxLength: 45 },
    source_name: { maxLength: 45 }
  };
}

export class Profile {
  /**  */
  'authenticated': boolean;

  /**  */
  'id': number;

  /**  */
  'username': string;

  /**  */
  'email': string;

  /**  */
  'first_name': string;

  /**  */
  'last_name': string;

  constructor(data: undefined | any = {}) {
    this['authenticated'] = data['authenticated'];
    this['id'] = data['id'];
    this['username'] = data['username'];
    this['email'] = data['email'];
    this['first_name'] = data['first_name'];
    this['last_name'] = data['last_name'];
  }

  public static validationModel = {
    authenticated: { required: true },
    id: { required: true },
    username: { required: true },
    email: { required: true },
    first_name: { required: true },
    last_name: { required: true }
  };
}

export class TokenObtain {
  /**  */
  'username': string;

  /**  */
  'password': string;

  constructor(data: undefined | any = {}) {
    this['username'] = data['username'];
    this['password'] = data['password'];
  }

  public static validationModel = {
    username: { required: true },
    password: { required: true }
  };
}

export class TokenObtainResponse {
  /**  */
  'access': string;

  /**  */
  'refresh': string;

  constructor(data: undefined | any = {}) {
    this['access'] = data['access'];
    this['refresh'] = data['refresh'];
  }

  public static validationModel = {
    access: { required: true },
    refresh: { required: true }
  };
}

export class TokenRefresh {
  /**  */
  'access': string;

  /**  */
  'refresh': string;

  constructor(data: undefined | any = {}) {
    this['access'] = data['access'];
    this['refresh'] = data['refresh'];
  }

  public static validationModel = {
    access: { required: true },
    refresh: { required: true }
  };
}

export class TokenRefreshResponse {
  /**  */
  'access': string;

  constructor(data: undefined | any = {}) {
    this['access'] = data['access'];
  }

  public static validationModel = {
    access: { required: true }
  };
}

export class Trade {
  /**  */
  'id': number;

  /**  */
  'created': Date;

  /**  */
  'updated': Date;

  /**  */
  'slug'?: string;

  /**  */
  'side'?: SideEnum;

  /**  */
  'symbol': string;

  /**  */
  'volume': string;

  /**  */
  'leverage'?: string;

  /**  */
  'base_fee'?: string;

  /**  */
  'date_open': Date;

  /**  */
  'date_close'?: Date;

  /**  */
  'duration'?: string;

  /**  */
  'price_open': string;

  /**  */
  'price_close'?: string;

  /**  */
  'min_price'?: string;

  /**  */
  'max_price'?: string;

  /**  */
  'pct_roi'?: string;

  /**  */
  'usd_roi'?: string;

  /**  */
  'min_pct_roi'?: string;

  /**  */
  'min_usd_roi'?: string;

  /**  */
  'max_pct_roi'?: string;

  /**  */
  'max_usd_roi'?: string;

  /**  */
  'min_margin'?: string;

  /**  */
  'max_margin'?: string;

  /**  */
  'bank'?: string;

  /**  */
  'close_reason'?: string;

  /**  */
  'opening_fees_usd'?: string;

  /**  */
  'closing_fees_usd'?: string;

  /**  */
  'bot'?: number;

  /**  */
  'bot_instance'?: number;

  /**  */
  'parent'?: number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['created'] = data['created'];
    this['updated'] = data['updated'];
    this['slug'] = data['slug'];
    this['side'] = data['side'];
    this['symbol'] = data['symbol'];
    this['volume'] = data['volume'];
    this['leverage'] = data['leverage'];
    this['base_fee'] = data['base_fee'];
    this['date_open'] = data['date_open'];
    this['date_close'] = data['date_close'];
    this['duration'] = data['duration'];
    this['price_open'] = data['price_open'];
    this['price_close'] = data['price_close'];
    this['min_price'] = data['min_price'];
    this['max_price'] = data['max_price'];
    this['pct_roi'] = data['pct_roi'];
    this['usd_roi'] = data['usd_roi'];
    this['min_pct_roi'] = data['min_pct_roi'];
    this['min_usd_roi'] = data['min_usd_roi'];
    this['max_pct_roi'] = data['max_pct_roi'];
    this['max_usd_roi'] = data['max_usd_roi'];
    this['min_margin'] = data['min_margin'];
    this['max_margin'] = data['max_margin'];
    this['bank'] = data['bank'];
    this['close_reason'] = data['close_reason'];
    this['opening_fees_usd'] = data['opening_fees_usd'];
    this['closing_fees_usd'] = data['closing_fees_usd'];
    this['bot'] = data['bot'];
    this['bot_instance'] = data['bot_instance'];
    this['parent'] = data['parent'];
  }

  public static validationModel = {
    id: { required: true },
    created: { required: true },
    updated: { required: true },
    slug: { maxLength: 1024 },
    symbol: { required: true, maxLength: 10 },
    volume: { required: true },
    date_open: { required: true },
    price_open: { required: true },
    close_reason: { maxLength: 45 }
  };
}

export type BlankEnum = '';
export type AllExchangeDataTypes = ExchangeDataEnum | BlankEnum | NullEnum;
export type AllStateTypes = BotInstanceStateEnum | BlankEnum | NullEnum;
export type AllOpenerClassTypes = OpenerClassEnum | BlankEnum | NullEnum;
export type AllCloserClassTypes = CloserClassEnum | BlankEnum | NullEnum;
export enum BotInstanceStateEnum {
  'stopped' = 'stopped',
  'paused' = 'paused',
  'running' = 'running'
}

export enum CloserClassEnum {
  'test' = 'test',
  'default' = 'default'
}
export type CombinedStatsTypes = ExchangeStats;
export type ExchangeDataEnum = phemex;
export type AllTypeTypes = TypeEnum | NullEnum;
export type NullEnum = '';

export enum OpenerClassEnum {
  'test' = 'test',
  'default' = 'default'
}

export enum OrderStateEnum {
  'open' = 'open',
  'closed' = 'closed',
  'cancelled' = 'cancelled',
  'failed' = 'failed'
}

export enum SideEnum {
  'long' = 'long',
  'short' = 'short'
}

export enum TypeEnum {
  'opener_class' = 'opener_class',
  'closer_class' = 'closer_class',
  'invest_function' = 'invest_function',
  'trade_strategy_function' = 'trade_strategy_function'
}
