import React from "react";
import { BotInstance, BotInstancesService } from "../../service";

export interface IBotInstanceListProps {
  botInstances?: BotInstance[];
}

const BotInstanceList = (props: IBotInstanceListProps): React.ReactElement => {
  const [botInstances, setBotInstances] = React.useState<BotInstance[]>([]);

  const fetchBotInstances = React.useCallback(async () => {
    const res = await BotInstancesService.botInstancesList();
    if (res && res.results) {
      setBotInstances(res.results);
    }
  }, []);

  React.useEffect(() => {
    if (props.botInstances) {
      setBotInstances(props.botInstances);
    } else {
      fetchBotInstances();
    }
  }, [fetchBotInstances, props.botInstances]);

  function getStateIconClasses(botInstanceState: string): string {
    const states: Record<string, string> = {
      running: "green ion-md-play",
      stopped: "red ion-md-square",
      paused: "ion-md-pause",
    };
    return states[botInstanceState] ?? "ion-ios-help-circle";
  }

  return (
    <>
      <div className="custom-table">
        <ul className="nav nav-pills" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-toggle="pill"
              href="#BotInstances"
              role="tab"
              aria-selected="true"
            >
              Bot Instances
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="BotInstances"
            role="tabpanel"
          >
            <table className="table">
              <thead>
                <tr>
                  <th>Slug</th>
                  <th>Group</th>
                  <th>State</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {botInstances.map((botInstance) => (
                  <tr key={botInstance.id}>
                    <td>{botInstance.slug}</td>
                    <td>{botInstance.group}</td>
                    <td className="text-center">
                      <i
                        className={`icon ${getStateIconClasses(
                          botInstance.state || ""
                        )}`}
                      />
                    </td>
                    <td>
                      {botInstance.test ? (
                        <span>TEST</span>
                      ) : (
                        <span className="green">LIVE</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BotInstanceList;
