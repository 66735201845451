import { resetAxios } from "../service/interceptor";
import { ActionMap } from "../utils/actions";

export type AppProps = {
  authenticated: boolean | undefined;
  theme: "dark" | "light";
  profile?: AppProfileProps;
};

export type AppProfileProps = {
  readonly userId?: number;
  readonly username?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly email?: string;
};

export const initialAppState: AppProps = {
  authenticated: undefined,
  theme: "dark"
};

export enum AppTypes {
  UpdateProfile = "UPDATE_PROFILE",
  ResetProfile = "RESET_PROFILE",
  SetTheme = "SET_THEME",
}

export type AppPayload = {
  [AppTypes.UpdateProfile]: {
    authenticated: boolean;
    userId?: number;
    username?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    image?: string;
  };
  [AppTypes.ResetProfile]: undefined;
  [AppTypes.SetTheme]: "dark" | "light";
};

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

export const AppReducer = (state: AppProps, action: AppActions) => {
  switch (action.type) {
    case AppTypes.UpdateProfile:
      return {
        ...state,
        authenticated: action.payload.authenticated,
        profile: {
          userId: action.payload.userId,
          username: action.payload.username,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          image: action.payload.image,
        },
      };
    case AppTypes.ResetProfile:
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      resetAxios();
      return {
        ...state,
        authenticated: false,
        profile: undefined,
      };
    case AppTypes.SetTheme:
      return {
        ...state,
        theme: action.payload
      };
    default:
      return state;
  }
};